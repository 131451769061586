import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter } from 'react-router-dom';

import Home from './Home.jsx';
import Portfolio from './Portfolio.jsx';
import Contact from './Contact.jsx';
import BallCanvas from './ball-canvas.js';

import '../styles/App.css';
import '../styles/Nav.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div id="App-container">
          <div id="App">
            <canvas id="bouncyBallCanvas"></canvas>
            <div id="content" className="App">
              <div>
                <Route exact path="/" component={Home} />
                <Route path="/portfolio" component={Portfolio} />
                <Route path="/contact" component={Contact} />
              </div>

              <div className="App-nav-container">
                <nav className="App-nav">
                  <div className="Nav-box">
                    <NavLink onClick={() => window.scrollTo(0, 0)} exact to="/" className="Nav-item">Home</NavLink>
                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/portfolio" className="Nav-item">Portfolio</NavLink>
                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/contact" className="Nav-item">Contact</NavLink>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}


window.onload = function() {
  let ballCanvas = new BallCanvas();
  ballCanvas.redraw(window.innerWidth, 400);
};


export default App;
