import React, { Component } from 'react';
import '../styles/Contact.css';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:    '',
      email:   '',
      message: '',
    };

    this.handleChangeName    = this.handleChangeName.bind(this);
    this.handleChangeEmail   = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
  }

  handleChangeName(event) {
    this.setState({
      email: this.state.email,
      name: event.target.value,
      message: this.state.message,
    });
  }
  handleChangeEmail(event) {
    this.setState({
      email: event.target.value,
      name: this.state.name,
      message: this.state.message,
    });
  }
  handleChangeMessage(event) {
    this.setState({
      email: this.state.email,
      name: this.state.name,
      message: event.target.value,
    });
  }

  render() {
    return (
      <div className="contact-content">
        <div className="contact-title">
          <span>Contact</span>
        </div>

        <div className="divider"></div>

        <div className="form-container">
          <form
            action="https://formspree.io/caleb@yapalabs.com"
            method="POST"
            >
            <div className="form-input">
              <label className={this.state.name.length > 0 ? 'visible' : 'hidden'}>Name</label>
              <input required type="text" placeholder="Name" className="text-input" name="name" value={this.state.name} onChange={this.handleChangeName} />
              <div className="full-bar" />
            </div>

            <div className="form-input">
              <label className={this.state.email.length > 0 ? 'visible' : 'hidden'}>Email</label>
              <input required type="text" placeholder="Email" className="text-input" name="replyto" value={this.state.email} onChange={this.handleChangeEmail} />
              <div className="full-bar" />
            </div>

            <div className="form-input">
              <label className={this.state.message.length > 0 ? 'visible' : 'hidden'}>Message</label>
              <textarea required placeholder="Message" className="text-input" rows="4" name="message" value={this.state.message} onChange={this.handleChangeMessage} />
              <div className="full-bar" />
            </div>

            <input className="submit-button" type="submit" value="Submit" />
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;