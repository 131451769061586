import React, { Component } from 'react';
import '../styles/Portfolio.css';
import Modal from './Modal';

import HotPiecesGame from '../portfolio-apps/hot-pieces-game.jsx';
import hotPiecesScreenShot from '../images/hotpieces-screenshot.png';
import GameOfLife from '../portfolio-apps/game-of-life.jsx';
import gameOfLifeScreenShot from '../images/game-of-life-screenshot.png';


class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = { modalIsOpen: false, modalChild: null };
  }

  openModal(child) {
    this.toggleFreezeBackground();

    this.setState({
      modalIsOpen: true,
      modalChild: child,
    });
  }
  closeModal() {
    this.toggleFreezeBackground();

    this.setState({
      modalIsOpen: false,
      modalChild: null,
    });
  }
  toggleFreezeBackground() {
    document.body.classList.toggle('noscroll', !this.state.modalIsOpen);
  }

  renderClients() {
    return (
      <div>
        <div>Some of the projects I'm working on include:</div>
        <div><a href="https://web.loope-app.com">Loope</a> - a scheduling tool
          for wedding photographers</div>
        <div><a href="https://sanesuite.com">SANEchart</a> - a digital charting
          system for forensic nurses</div>
        <div><a href="https://cryptoamex.com">Cryptoamex</a> - a crypto-currency
          trading platform.</div>
      </div>
    );
  }

  renderHotPieces() {
    return <PortfolioItem
      key="hotpiecesgame"
      itemTitle="Hot Pieces Game"
      itemDescription="Increase points by getting four of the same type
        adjacent so they disappear."
      itemImage={hotPiecesScreenShot}
      itemImageAlt="hot pieces game"
      clicked={() => this.openModal(<HotPiecesGame />)}
      />;
  }

  renderGameOfLife() {
    return <PortfolioItem
      key="gameoflife"
      itemTitle="Conway's Game of Life"
      itemDescription="A zero player game of evolution."
      itemImage={gameOfLifeScreenShot}
      itemImageAlt="game of life"
      clicked={() => this.openModal(<GameOfLife />)}
      />;
  }

  portfolioItem(props) {

  }

  render() {
    return (
      <div id="portfolio-page">
        <Modal show={this.state.modalIsOpen}
          onClose={() => {this.closeModal()}}
          children={this.state.modalChild}
          />

        <div className="portfolio-content">
          <div className="portfolio-title">
            <span>Portfolio</span>
          </div>
          <div className="portfolio-intro">
            <span>These are a few of the things I've been working on lately.
              Code available at <a href="https://git.yapalabs.com">
              git.yapalabs.com</a> as I'm able to post it.
              <br />More to come soon.</span>
          </div>

          <div className="divider"></div>

          {this.renderClients()}

          <div className="divider"></div>

          {this.renderHotPieces()}
          {this.renderGameOfLife()}
        </div>
      </div>
    );
  }
}

/**
 * @param {hash} props
 *   itemTitle,
 *   itemDescription,
 *   itemImage,
 *   itemImageAlt,
 *   clicked - what happens when the image is clicked
 */
const PortfolioItem = props => {
  return (
    <div className="portfolio-item">
      <div className="portfolio-item-title">
        <span>{props.itemTitle}</span>
      </div>
      <div className="portfolio-item-description">
        <span>{props.itemDescription}</span>
      </div>
      <div className="portfolio-item-wrapper">
        <div id="image-container">
          <div className="portfolio-item-image">
            <img
              src={props.itemImage}
              alt={props.itemImageAlt}
              onClick={props.clicked}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
