/*
 * This constelation code was inspired by a snippet I found on codepen.io.
 * https://codepen.io/harrymilnes/pen/BWEEOR?editors=0010
 *
 * Classes contained in this file:
 * - BallCanvas (exported)
 * - BouncyBall (internal)
 */

let speedMultiplier = 0.1;
let densityMultiplier = 4;

class BallCanvas {
  constructor() {
    this.bouncyBallList = [];
    this.canvas = document.getElementById("bouncyBallCanvas");
    this.ctx = this.canvas.getContext("2d");
    window.requestAnimFrame(this.animate.bind(this));
  }

  redraw(width, height) {
    this.bouncyBallList = [];
    this.canvas.width = width;
    this.canvas.height = height;
    this.spawnBalls();
  }

  spawnBalls() {
    for (let i = 0; i < this.density(); i++) {
      this.bouncyBallList.push(new BouncyBall(this));
    }
  }

  density() {
    return Math.floor(Math.sqrt((this.canvas.height, this.canvas.width) * densityMultiplier));
  }

  animate() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.bouncyBallList.forEach(function(ball) {
      ball.update();
    });
    window.requestAnimFrame(this.animate.bind(this));
  }

  drawLine(startX, startY, endX, endY, distance) {
    distance = ((1.0 / distance) * 10).toFixed(2);
    this.ctx.beginPath();
    this.ctx.moveTo(startX, startY);
    this.ctx.lineTo(endX, endY);
    this.ctx.strokeStyle = "rgba(96, 99, 80," + distance + ")";
    this.ctx.lineWidth = 0.3;
    this.ctx.stroke();
  }

  drawBall(ball) {
    this.ctx.beginPath();
    this.ctx.arc(ball.spawnX, ball.spawnY, ball.size, 0, 2 * Math.PI);
    this.ctx.fillStyle = "rgba(120, 120, 100, 1.0)";
    this.ctx.fill();
    this.ctx.strokeStyle = "rgba(120, 120, 100, 1.0)";
    this.ctx.stroke();
  }
}


class BouncyBall {
  constructor(_ballCanvas) {
    this.ballCanvas = _ballCanvas;
    this.spawnX = Math.floor(Math.random() * (this.ballCanvas.canvas.width));
    this.spawnY = Math.floor(Math.random() * (this.ballCanvas.canvas.height));
    this.speedX = Math.random() * generateDecimalBetween(-1.0, 1.0);
    this.speedY = Math.random() * generateDecimalBetween(-1.0, 1.0);
    this.size = generateDecimalBetween(0.3, 1.3);
    return this;
  }

  seekLines() {
    for (let i = 0; i < this.ballCanvas.bouncyBallList.length; i++)
    {
      let dx = this.ballCanvas.bouncyBallList[i].spawnX - this.spawnX;
      let dy = this.ballCanvas.bouncyBallList[i].spawnY - this.spawnY;
      let distance = Math.sqrt(dx * dx + dy * dy);

      if (this.ballCanvas.bouncyBallList[i] !== this &&
          this.ballCanvas.bouncyBallList[i].linkedbouncyBalls != null &&
          distance < this.ballCanvas.density() &&
          !this.linkedbouncyBalls.includes(this.ballCanvas.bouncyBallList[i])
      ) {
        this.ballCanvas.drawLine(this.spawnX,
                            this.spawnY,
                            this.ballCanvas.bouncyBallList[i].spawnX,
                            this.ballCanvas.bouncyBallList[i].spawnY,
                            distance);
        this.linkedbouncyBalls.push(this.ballCanvas.bouncyBallList[i]);
      }
    }
  }

  doesBallIntersectCanvasBoundary() {
    return this.spawnX < 0 + this.size ||
           this.spawnX > this.ballCanvas.canvas.width - this.size ||
           this.spawnY < 0 + this.size ||
           this.spawnY > this.ballCanvas.canvas.height - this.size;
  }

  update() {
    this.spawnX = this.spawnX - (this.speedX * speedMultiplier);
    this.spawnY = this.spawnY - (this.speedY * speedMultiplier);

    if (this.doesBallIntersectCanvasBoundary() && this.wasSpawnedByClick) {
        this.ballCanvas.bouncyBallList.splice(this.ballCanvas.bouncyBallList.indexOf(this), 1);
        return;
    }
    if (this.spawnX < 0 + this.size || this.spawnX > this.ballCanvas.canvas.width - this.size) {
        this.speedX = this.speedX * -1;
    } else if (this.spawnY < 0 + this.size || this.spawnY > this.ballCanvas.canvas.height - this.size) {
        this.speedY = this.speedY * -1;
    }

    this.linkedbouncyBalls = [];
    this.seekLines();
    this.ballCanvas.drawBall(this);
  }
}


function generateDecimalBetween(minimum, maximum) {
  return (Math.random() * (minimum - maximum) + maximum).toFixed(2);
};

window.requestAnimFrame = (function() {
  return  window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||

  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };
})();


export default BallCanvas;
