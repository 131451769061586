import React, { Component } from 'react';
import './hot-pieces-game.css';



/*****************************************************************************/
/*** game.js ***/

const shapeTypes = [
  'square',
  'circle',
  'triangle',
  'hexagon',
  'diamond',
  'cross',
];
let cells = [];
let history = [];
let lastSelectedCell = -1;
let score = 0;
let numOfRemovedPieces = 0;
let setsRemoved = false;
let undoHistSlot = 0;
let undoDisabled = true;
let redoDisabled = true;
let animateMove = true;


class HotPiecesGame extends Component {
  constructor(props) {
    super(props);

    cells = Array(90).fill(null);
    for (let n=0; n<cells.length; n++) {
      cells[n] = {
        key: n,
        selected: false,
        shape: 'blank',
        row: Math.floor(n / 9),
        col: n % 9,

        // These attributes are used in the path finding
        // TODO can I add these attributes when they're passed to the path finder?
        //   might help keep them immutable
        isGoal: false,
        visited: false,
        previous: null,
      };
    }

    this.initializeBoard();

    this.state = {
      cells: cells,
    };
  }

  render() {
    return (
      <div className="HotPiecesGame Game">
        <div className="Game-body">
          <Board
            onClick={(n) => this.cellClicked(n)}
            //TODO is this why movePath animations weren't working?  Should I get
            //  rid of state, and just use cells and history?
            cells={this.state.cells}
            score={score}
            undoDisabled={undoDisabled}
            redoDisabled={redoDisabled}
          />
        </div>
      </div>
    );
  }


  initializeBoard() {
    this.populateThreeRandomCells();
    this.snapShotHistory();
  }

  async cellClicked(cellNumber) {
    if (cellNumber === 'undo') {
      this.undo();
    } else if (cellNumber === 'redo') {
      this.redo();
    } else {
      cells = this.state.cells.slice();

      if (this.aCellIsSelected()) {
        if (lastSelectedCell === cellNumber) {
          this.deselectCell(cellNumber);
        } else {
          if (this.cellIsBlank(cellNumber)) {
            await this.makeAMove(cellNumber);
            this.updateHistoryFromMove();
          } else {
            this.deselectCell(lastSelectedCell);
            this.selectCell(cellNumber);
          }
        }
      } else if (!this.cellIsBlank(cellNumber)) {
        this.selectCell(cellNumber);
      }
    }

    this.setState({
      cells: cells,
    });
  }


  cellSelected(cellNumber) {
    return cells[cellNumber].selected;
  }
  cellIsBlank(cellNumber) {
    return cells[cellNumber].shape === 'blank';
  }
  aCellIsSelected() {
    return lastSelectedCell > -1 && cells[lastSelectedCell].selected;
  }
  pathExistsTo(toCell) {
    // TODO need to pass immutable cells object
    return PathFinder.validPathFromTo(cells, lastSelectedCell, toCell);
  }


  updateHistoryFromMove() {
    if (undoHistSlot > 0) {
      this.resetHistoryLaterThanCurrentPosition();
      undoHistSlot = 0;
      redoDisabled = true;
    }

    this.snapShotHistory();
    this.tryEnableUndo();
    this.truncateHistoryIfLongerThan(6);
  }
  resetHistoryLaterThanCurrentPosition() {
    for (; undoHistSlot>0; --undoHistSlot) {
      history.shift();
    }
  }
  snapShotHistory() {
    history.unshift({
      shapes: this.getShapesForHistory(),
      score: score,
    });
  }
  getShapesForHistory() {
    return cells.map( cell => cell.shape );
  }
  tryDisableUndo() {
    undoHistSlot++;
    if (undoHistSlot > history.length - 2) {
      undoDisabled = true;
    }
  }
  tryEnableUndo() {
    if (undoHistSlot < history.length) {
      undoDisabled = false;
    }
  }
  tryDisableRedo() {
    undoHistSlot--;
    if (undoHistSlot < 1) {
      redoDisabled = true;
    }
  }
  tryEnableRedo() {
    if (undoHistSlot > 0) {
      redoDisabled = false;
    }
  }
  undo() {
    this.tryDisableUndo();  // also increments slot counter
    this.tryEnableRedo();
    this.editCellsFromHistory();
    this.editScoreFromHistory();
  }
  redo() {
    this.tryDisableRedo();  // also decrements slot counter
    this.tryEnableUndo();
    this.editCellsFromHistory();
    this.editScoreFromHistory();
  }
  truncateHistoryIfLongerThan(maxSize) {
    if (history.length > maxSize) {
      history.pop();
    }
  }
  editCellsFromHistory() {
    const shapeHistory = history[undoHistSlot].shapes;
    for (let n=0; n<cells.length; n++) {
      cells[n].shape = shapeHistory[n];
    }
  }
  editScoreFromHistory() {
    score = history[undoHistSlot].score;
  }


  selectCell(cellNumber) {
    cells[cellNumber].selected = true;
    lastSelectedCell = cellNumber;
  }
  deselectCell(cellNumber) {
    cells[cellNumber].selected = false;
    lastSelectedCell = -1;
  }
  moveFromTo(fromCell, toCell) {
    cells[toCell].shape = cells[fromCell].shape;
    cells[fromCell].shape = 'blank';
    this.setState({ cells: cells });
  }


  populateThreeRandomCells() {
    const randomShape = function() {
      return shapeTypes[Math.floor(Math.random() * shapeTypes.length)];
    };
    const randomCellNum = function() {
      return Math.floor(Math.random() * cells.length);
    };

    for (let n=0; n<3; n++) {
      let randomNumber = randomCellNum();

      // Make sure the cell in question is blank, otherwise find a new number.
      while (cells[randomNumber].shape !== 'blank') {
        randomNumber = randomCellNum();
      }

      cells[randomNumber].shape = randomShape();
    }
  }

  async makeAMove(toCell) {
    let movePath = this.pathExistsTo(toCell);
    if (movePath.length > 0) {
      if (animateMove) {
        await this.animatedMove(movePath);
      } else {
        this.moveFromTo(lastSelectedCell, toCell);
        this.deselectCell(lastSelectedCell);
      }
      this.removeSets();  // TODO do we need both of these removeSets's?
      if (setsRemoved) {
        setsRemoved = false;
      } else {
        this.removeFromScore();
        this.populateThreeRandomCells();
        this.removeSets();  // TODO do we need both of these removeSets's?
      }
    } else {
      this.deselectCell(lastSelectedCell);
    }
  }
  async animatedMove(path) {
    this.deselectCell(lastSelectedCell);

    for (let n=1; n<path.length; n++) {
      this.moveFromTo(path[n-1], path[n]);
      await sleep(20);
    }
  }

  addToScore() {
    score += (numOfRemovedPieces * 2);
  }
  removeFromScore() {
    if (score > 0) {
      score -= 1;
    }
  }

  removeSets() {
    for (let cellNumber=0; cellNumber<cells.length; cellNumber++) {
      if (!this.cellIsBlank(cellNumber)) {
        if (this.removeInDirection('south', cellNumber, 0, cells[cellNumber].shape) ||
            this.removeInDirection('east',  cellNumber, 0, cells[cellNumber].shape) ) {
              setsRemoved = true;
              this.addToScore();
        }
      }
    }
  }

  removeInDirection(direction, cellNumber, depth, shape) {
    let maxDepth;
    const neighborNumber = this.getNeightborInDirection(direction, cellNumber);

    if (cellNumber < 0 || shape !== cells[cellNumber].shape) {
      return depth;
    }

    if ((maxDepth = this.removeInDirection(direction, neighborNumber, depth + 1, shape)) > 3) {
      cells[cellNumber].shape = 'blank';
      numOfRemovedPieces = maxDepth;
      return maxDepth;
    } else {
      return false;
    }
  }

  /**
   * return: -1 if no neighbor in that direction, otherwise the neighbor's ID (0-89).
   * @param {south or east (string)} direction
   * @param {current cell to search from (integer between 0 and 89)} cellNumber
   */
  getNeightborInDirection(direction, cellNumber) {
    if (cellNumber < 0) {
      return -1;
    }
    if (direction === 'south' && cells[cellNumber].row < 9) {
      return cellNumber + 9;
    } else if (direction === 'east' && cells[cellNumber].col < 8) {
      return cellNumber + 1;
    } else {
      return -1;
    }
  }
}


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



/*****************************************************************************/
/*** board.js ***/

class Board extends Component {
  renderCell(n) {
    return (
      <Cell
        key={n}
        value={this.props.cells[n]}
        onClick={() => this.props.onClick(n)}
        shape={this.props.cells[n] && this.props.cells[n].shape}
      />
    );
  }

  render() {
    let rows    = [];
    let cells = [];
    const rowCount = 10;
    const colCount = 9;
    let cellNumber = 0;

    //To isolate these styles since they overlap with the Game of Life app.
    const boardRowStyle = {
      margin: 0,
      padding: 0,
    };

    for (let row=0; row<rowCount; row++) {
      for (let col=0; col<colCount; col++) {
        cells.push(this.renderCell(cellNumber));
        cellNumber++;
      }

      rows.push(
        <div
          key={row}
          className="board-row"
          style={boardRowStyle}>
          {cells}
        </div>
      );
      cells = [];
    }

    return (
      <div className="Game-board">
        <div className="game-board-head">
          <button
            className={"undo left" + (this.props.undoDisabled ? ' disabled' : '')}
            onClick={() => this.props.onClick('undo')}
            >
            undo
          </button>
          <button
            className={"redo right" + (this.props.redoDisabled ? ' disabled' : '')}
            onClick={() => this.props.onClick('redo')}
            >
            redo
          </button>
          <div className="score">Score: {this.props.score}</div>
        </div>
        {rows}
      </div>
    );
  }
}



/*****************************************************************************/
/*** cell.js ***/

class Cell extends React.Component {
  render() {
    return (
      <div
        className={'cell' + (this.props.value.selected ? ' selected' : '')}
        onClick={() => this.props.onClick()}
        >
        <div className={'shape ' + this.props.shape}></div>
      </div>
    );
  }
}



/*****************************************************************************/
/*** path-finder.js ***/

var PathFinder = {
  /**
   * return: empty list if no path, else list of cell id's from beginning to end.
   *
   * @param {list of cells} cells
   * @param {source} fromCell
   * @param {destination} toCell
   */
  validPathFromTo(cells, fromCell, toCell) {
    let path = [];

    cells[toCell].isGoal = true;
    if (this.breadthFirstSearchPathExists(cells, fromCell)) {
      path = this.aggregatePath(cells, toCell);
    }

    this.resetCells(cells);
    return path;
  },

  breadthFirstSearchPathExists(cells, fromCell) {
    let node;      // int
    let neighbor;  // int
    let neighborsOfNode = [];
    let queue = [];

    queue.push(fromCell);
    cells[fromCell].visited = true;

    while (queue.length > 0) {
      node = queue.shift();

      if (cells[node].isGoal) {
        return true;
      }

      neighborsOfNode = this.getNeighborsOfNode(cells, node);
      for (let n=0; n<neighborsOfNode.length; n++) {
        neighbor = neighborsOfNode[n];
        if (!cells[neighbor].visited) {
          queue.push(neighbor);
          cells[neighbor].previous = node;
          cells[neighbor].visited = true;
        }
      }
    }

    return false;
  },

  getNeighborsOfNode(cells, cell) {
    let neighbors = [];
    let emptyNeighbors = [];

    if (cells[cell].row > 0) {
      neighbors.push(cell - 9);
    }
    if (cells[cell].row < 9) {
      neighbors.push(cell + 9);
    }
    if (cells[cell].col > 0) {
      neighbors.push(cell - 1);
    }
    if (cells[cell].col < 8) {
      neighbors.push(cell + 1);
    }

    for (let n=0; n<neighbors.length; n++) {
      if (cells[neighbors[n]].shape === 'blank') {
        emptyNeighbors.push(neighbors[n]);
      }
    }

    return emptyNeighbors;
  },

  resetCells(cells) {
    for (let n=0; n<cells.length; n++) {
      cells[n].previous = null;
      cells[n].visited = false;
      cells[n].isGoal = false;
    }
  },

  /**
   * follows the linked cells (via .previous) from the goal to the point where
   * there are no more previouses
   *
   * return: list containing the shortest path from source to destination
   *
   * @param {list of cells} cells
   * @param {the last cell to work back grom} goalCell
   */
  aggregatePath(cells, goalCell) {
    let path = [];
    path.unshift(goalCell);

    let cell = goalCell;
    while (cells[cell].previous !== null) {
      cell = cells[cell].previous;
      path.unshift(cell);
    }

    return path;
  },
}

export default HotPiecesGame;
