import React, { Component } from 'react';
import './game-of-life.css';


/*****************************************************************************/
/*** App.jsx ***/

let speed           = 'Mid';
let gameRunning     = false;
let rowCount        = 20;
let colCount        = 40;
let totalCells      = rowCount * colCount;
let cells        = [];
let cellsToKill  = [];
let cellsToRaise = [];

class GameOfLife extends Component {
  constructor(props) {
    super(props);

    this.initializeCells();
    this.setRandomCreaturesToCells();

    this.state = {
      cells: cells,
      startButtonLabel: "Start Game",
      resetButtonLabel: "Clear",
    };
  }

  componentWillUnmount() {
    speed = 'Mid';
    this.clearCreatures();
    clearInterval(this.timer);
  }

  handleClickReset() {
    let resetButtonLabel = '';
    if (this.state.resetButtonLabel === 'Clear') {
      resetButtonLabel = 'Reset';
      this.clearCreatures();
    } else {
      resetButtonLabel = 'Clear';
      this.addRandomCreatures();
    }

    this.setState({
      cells: this.state.cells,
      startButtonLabel: this.state.startButtonLabel,
      resetButtonLabel: resetButtonLabel,
    });
  }

  handleStep() {
    this.tick();
  }

  handleClickStartPause() {
    if (gameRunning) {
      this.pauseGame();
    } else {
      this.startGame();
    }
  }

  handleCycleSpeed() {
    this.nextSpeed();
    this.setState(this.state);

    if (gameRunning) {
      this.pauseGame();
      this.startGame();
    }
  }

  handleCellClicked(cellID) {
    let state = 'alive';
    if (cells[cellID].state === 'alive') {
      state = 'dead';
    }
    cells[cellID].state = state;

    this.setState({
      cells: cells,
      startButtonLabel: this.state.startButtonLabel,
      resetButtonLabel: this.state.resetButtonLabel,
    });
  }

  render() {
    return (
      <div className="GameOfLife">
        <header className="GameOfLife-header">
          <h1 className="GameOfLife-title">A Game of Life</h1>
        </header>
        <div className="GameOfLife-intro">
          <span>Rules</span>
          <ul>
            <li>Any live cell with fewer than two live neighbors dies, as if by under population.</li>
            <li>Any live cell with two or three live neighbors lives on to the next generation.</li>
            <li>Any live cell with more than three live neighbors dies, as if by overpopulation.</li>
            <li>Any dead cell with exactly three live neighbors becomes a live cell, as if by reproduction.</li>
          </ul>
        </div>
        <LifeBoard
          cells={cells}
          rowCount={rowCount}
          colCount={colCount}
          onClick={(n) => this.handleCellClicked(n)}
          />
        <div>
          <div className="game-buttons">
            <button
              id="reset-board-button"
              onClick={() => this.handleClickReset()}
              >{this.state.resetButtonLabel}</button>
            <button
              id="step-button"
              onClick={() => this.handleStep()}
              >Step</button>
            <button
              id="start-pause-button"
              onClick={() => this.handleClickStartPause()}
              >{this.state.startButtonLabel}</button>
            <button
              id="cycle-button"
              onClick={() => this.handleCycleSpeed()}
              >Speed ({speed})</button>
          </div>
        </div>
      </div>
    );
  }

  initializeCells() {
    let num = 0;

    for (let y=0; y<rowCount; y++) {
      for (let x=0; x<colCount; x++) {
        cells.push({
          key: num,
          state: 'dead',
          row: y,
          col: x,
        });

        num++;
      }
    }
  }

  clearCreatures() {
    for (let cellID=0; cellID<totalCells; cellID++) {
      cells[cellID].state = 'dead';
    }

    this.setState({
      cells: cells,
      startButtonLabel: this.state.startButtonLabel,
      resetButtonLabel: this.state.resetButtonLabel,
    });
  }

  addRandomCreatures() {
    this.clearCreatures();
    this.setRandomCreaturesToCells();

    this.setState({
      cells: cells,
      startButtonLabel: this.state.startButtonLabel,
      resetButtonLabel: this.state.resetButtonLabel,
    });
  }

  setRandomCreaturesToCells() {
    const states = [ 'alive', 'dead' ];

    for (let cellID=0; cellID<totalCells; cellID++) {
      cells[cellID].state = states[Math.floor(Math.random() * 2)];
    }
  }

  nextSpeed() {
    switch (speed) {
      case 'Low':
        speed = 'Mid';
        break;
      case 'Mid':
        speed = 'High';
        break;
      case 'High':
        speed = 'Low';
        break;
      default:
        break;
    }
  }

  startGame() {
    gameRunning = true;
    this.startStopTimer();
    this.changeStartStopButtonTo('pause');
  }

  pauseGame() {
    gameRunning = false;
    this.startStopTimer();
    this.changeStartStopButtonTo('start');
  }

  startStopTimer() {
    let speedMultiplier = 0;  // High speed
    switch (speed) {
      case 'Low':
        speedMultiplier = 2;
        break;
      case 'Mid':
        speedMultiplier = 1;
        break;
      default:
        break;
    }

    // Kinda sorta threading using babel's timer features.
    // Got this idea from: https://codepen.io/smonn/pen/KzezEw
    if (gameRunning) {
      this.timer = setInterval(() => this.tick(), 50 + (250 * speedMultiplier));
    } else {
      clearInterval(this.timer);
    }
  }

  changeStartStopButtonTo(command) {
    let buttonLabel = "Start Game";

    if (command === 'pause') {
      buttonLabel = "Pause Game";
    }

    this.setState({
      cells: this.state.cells,  //TODO can I ignore this? will it mess up the state?
      startButtonLabel: buttonLabel,
      resetButtonLabel: this.state.resetButtonLabel,
    });
  }

  tick() {
    this.evaluateGameRules();
  }

  /*
   * Game rules:
   * - Any live cell with fewer than two live neighbors dies, as if by under population.
   * - Any live cell with two or three live neighbors lives on to the next generation.
   * - Any live cell with more than three live neighbors dies, as if by overpopulation.
   * - Any dead cell with exactly three live neighbors becomes a live cell, as if by reproduction.
   */
  evaluateGameRules() {
    let neighborIDs = [];
    let liveNeighborCount = 0;

    for (let cellID=0; cellID<totalCells; cellID++) {
      neighborIDs = this.getNeighborsOf(cellID);
      liveNeighborCount = this.countLiveNeighbors(neighborIDs);

      this.evaluateRule1(cellID, liveNeighborCount);
      this.evaluateRule2(cellID, liveNeighborCount);
      this.evaluateRule3(cellID, liveNeighborCount);
      this.evaluateRule4(cellID, liveNeighborCount);

      neighborIDs = [];
      liveNeighborCount = 0;
    }

    this.raiseAndKillCells();

    this.setState({
      cells: cells,
      startButtonLabel: this.state.startButtonLabel,  //TODO can I ignore this? will it mess up the state?
      resetButtonLabel: this.state.resetButtonLabel,
    });
  }

  getNeighborsOf(cellID) {
    let neighbors = [];
    let id = -1;

    // Northwest
    id = cellID - colCount - 1;
    if (id > -1  &&  cells[id].col === cells[cellID].col - 1) {
      neighbors.push(id);
    }
    // North
    id = cellID - colCount;
    if (id > -1) {
      neighbors.push(id);
    }
    // Northeast
    id = cellID - colCount + 1;
    if (id > -1  &&  cells[id].col === cells[cellID].col + 1) {
      neighbors.push(id);
    }
    // West
    id = cellID - 1;
    if (id > -1  &&  cells[id].row === cells[cellID].row) {
      neighbors.push(id);
    }
    // East
    id = cellID + 1;
    if (id < totalCells  &&  cells[id].row === cells[cellID].row) {
      neighbors.push(id);
    }
    // Southwest
    id = cellID + colCount - 1;
    if (id < totalCells  &&  cells[id].col === cells[cellID].col - 1) {
      neighbors.push(id);
    }
    // South
    id = cellID + colCount;
    if (id < totalCells) {
      neighbors.push(id);
    }
    // Southeast
    id = cellID + colCount + 1;
    if (id < totalCells  &&  cells[id].col === cells[cellID].col + 1) {
      neighbors.push(id);
    }

    return neighbors;
  }

  countLiveNeighbors(neighborIDs) {
    let count = 0;

    for (let i=0; i<neighborIDs.length; i++) {
      if (cells[neighborIDs[i]].state === 'alive') {
        count++;
      }
    }

    return count;
  }


  // Any live cell with fewer than two live neighbors dies, as if by under population.
  evaluateRule1(cellID, liveNeighborCount) {
    if (cells[cellID].state === 'alive'  &&  liveNeighborCount < 2) {
      cellsToKill.push(cellID);
    }
  }
  // Any live cell with two or three live neighbors lives on to the next generation.
  evaluateRule2(cellID, liveNeighborCount) {
    if (cells[cellID].state === 'alive'  &&  (liveNeighborCount === 2 || liveNeighborCount === 3)) {
      return;
    } else {
      cellsToKill.push(cellID);
    }
  }
  // Any live cell with more than three live neighbors dies, as if by overpopulation.
  evaluateRule3(cellID, liveNeighborCount) {
    if (cells[cellID] === 'alive'  &&  liveNeighborCount > 3) {
      cellsToKill.push(cellID);
    }
  }
  // Any dead cell with exactly three live neighbors becomes a live cell, as if by reproduction.
  evaluateRule4(cellID, liveNeighborCount) {
    if (cells[cellID].state === 'dead'  &&  liveNeighborCount === 3) {
      cellsToRaise.push(cellID);
    }
  }

  raiseAndKillCells() {
    for (let i=0; i<cellsToKill.length; i++) {
      cells[cellsToKill[i]].state = 'dead';
    }
    for (let i=0; i<cellsToRaise.length; i++) {
      cells[cellsToRaise[i]].state = 'alive';
    }

    cellsToKill  = [];
    cellsToRaise = [];
  }
}



/*****************************************************************************/
/*** life-board.jsx ***/

class LifeBoard extends Component {
  renderLifeCells() {
    let num = 0;
    let rows = [];
    let cells = [];

    // To isolate this style since it doesn't play well with the hot pieces game.
    const boardRowStyle = {
      display: 'flex',
    };

    for (let y=0; y<this.props.rowCount; y++) {
      for (let x=0; x<this.props.colCount; x++) {
        cells.push(this.renderCell(num));
        num++;
      }
      rows.push(<div key={y} className="board-row" style={boardRowStyle}>{cells}</div>);
      cells = [];
    }
    return rows;
  }

  renderCell(cellID) {
    return (
      <LifeCell
        key={cellID}
        state={this.props.cells[cellID].state}
        onClick={() => this.props.onClick(cellID)}
        />
    );
  }

  render() {
    return (
      <div id="life-board">
        {this.renderLifeCells()}
      </div>
    );
  }
}



/*****************************************************************************/
/*** life-cell.jsx ***/

class LifeCell extends Component {
  render() {
    return (
      <div
        id="life-cell"
        className={this.props.state}
        onClick={() => this.props.onClick()}
        ></div>
    );
  }
}


export default GameOfLife;
