import React, { Component } from 'react';
import '../styles/Home.css';
import personalPhoto from '../images/headshot2.jpg';
import linkedIn from '../images/linkedin.svg';

class Home extends Component {
  render() {
    return (
      <div className="home-content">
        <div className="introduction">
          <div className="name">yapaLabs</div>
          <div className="tagline">Full Stack Web Development, React on Ruby on Rails on Unix on ...</div>
        </div>

        <div className="personal-description">
          <p>Hello, My name is Caleb Pope.  I'm a full stack web developer.</p>
          <p>I cut my teeth on Ruby on Rails while working for a small development
            company.  I helped build low-cost, high-quality custom sites and apps
            for religious and non-profit organizations.</p>
          <p>I am passionate about learning, coding, the outdoors, and anything
            with wheels or propellors.  I get really excited
            when I can combine any number of those.  </p>
        </div>

        <div className="personal-photo">
          <img src={personalPhoto} className="personal-photo-image" alt="personal" />
        </div>

        <div className="links-box">
          <div className="link-item linkedin">
            <a href="https://www.linkedin.com/in/thecalebpope" target="_blank" rel="noopener noreferrer">
              <img src={linkedIn} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>

    );
  }
}

export default Home;
